import { createSlice } from "@reduxjs/toolkit";

const initialShotEvolutionReportState = {
  reportName: "",
  columnStates: [
    {
      columnName: "",
      selectedPlayer: null,
      selectedOptions: {},
      yearOptions: [],
      surfaceOptions: [],
      tournamentOptions: [],
      allMatchData: [],
      filteredMatchData: [],
      selectedMatchData: [],
      temporarySelection: [],
    },
    {
      columnName: "",
      selectedPlayer: null,
      selectedOptions: {},
      yearOptions: [],
      surfaceOptions: [],
      tournamentOptions: [],
      allMatchData: [],
      filteredMatchData: [],
      selectedMatchData: [],
      temporarySelection: [],
    },
  ],
};

const shotEvolutionReportSlice = createSlice({
  name: "shotEvolutionReport",
  initialState: initialShotEvolutionReportState,
  reducers: {
    setReportName(state, action) {
      state.reportName = action.payload;
    },

    setColumnName(state, action) {
      const { index, columnName } = action.payload;
      state.columnStates[index].columnName = columnName;
    },

    setTemporarySelection(state, action) {
      const { index, temporarySelection } = action.payload;
      state.columnStates[index].temporarySelection = temporarySelection;
    },

    setColumnState(state, action) {
      const { index, columnState } = action.payload;
      state.columnStates[index] = {
        ...state.columnStates[index],
        ...columnState,
      };
      const selectedTournaments =
        columnState.selectedOptions["tournaments"]?.map((t) => t.value) ?? [];
      const selectedSurfaces =
        columnState.selectedOptions["surfaces"]?.map((s) => s.value) ?? [];
      const selectedYears =
        columnState.selectedOptions["years"]?.map((y) => y.value) ?? [];
      const selectedHandedness =
        columnState.selectedOptions["handedness"]?.value;

      // no data loaded yet
      if (!columnState.allMatchData) {
        return;
      }

      //filter data based on current filters
      const filteredMatchData = columnState.allMatchData.filter((item) => {
        return (
          (selectedTournaments.length === 0 ||
            selectedTournaments.includes(item.tournament)) &&
          (selectedSurfaces.length === 0 ||
            selectedSurfaces.includes(item.surface)) &&
          (selectedYears.length === 0 || selectedYears.includes(item.year)) &&
          (selectedHandedness === "BOTH" ||
            selectedHandedness === item.handedness)
        );
      });

      // filter selected match data
      const selectedMatchData = columnState.selectedMatchData;
      state.columnStates[index].selectedMatchData =
        selectedMatchData?.filter((s) =>
          filteredMatchData
            .map((f) => f.shotEvolutionReportId)
            .includes(s.shotEvolutionReportId)
        ) ?? [];

      state.columnStates[index].filteredMatchData = filteredMatchData;

      const allMatchData = columnState.allMatchData;

      // get values for filters
      const years = [
        ...new Set(
          (selectedYears?.length > 0 ? allMatchData : filteredMatchData).map(
            (item) => item.year
          )
        ),
      ]
        .sort((a, b) => a - b)
        .map((value) => ({ value, label: value }));

      const surfaces = [
        ...new Set(
          (selectedSurfaces?.length > 0 ? allMatchData : filteredMatchData).map(
            (item) => item.surface
          )
        ),
      ].map((value) => ({ value, label: value }));

      // allow only tournaments that are satisfying year and surface filters
      const allowedTournaments = [
        ...new Set(
          allMatchData
            .filter(
              (m) =>
                (selectedYears.length === 0 ||
                  selectedYears.includes(m.year)) &&
                (selectedSurfaces.length === 0 ||
                  selectedSurfaces.includes(m.surface))
            )
            .map((m) => m.tournament)
        ),
      ].map((value) => ({ value, label: value }));

      state.columnStates[index].yearOptions = years;
      state.columnStates[index].surfaceOptions = surfaces;
      state.columnStates[index].tournamentOptions = allowedTournaments;
    },

    updateColumnOptions(state, action) {
      const { index, playerData } = action.payload;

      const years = [...new Set(playerData.map((item) => item.year))]
        .sort((a, b) => a - b)
        .map((value) => ({ value, label: value }));
      const surfaces = [...new Set(playerData.map((item) => item.surface))].map(
        (value) => ({ value, label: value })
      );
      const tournaments = [
        ...new Set(playerData.map((item) => item.tournament)),
      ].map((value) => ({ value, label: value }));
      state.columnStates[index].yearOptions = years;
      state.columnStates[index].surfaceOptions = surfaces;
      state.columnStates[index].tournamentOptions = tournaments;
      state.columnStates[index].allMatchData = playerData;
      const selectedHandedness =
        state.columnStates[index].selectedOptions["handedness"]?.value;
      const filteredMatchData = playerData.filter(
        (item) =>
          item.handedness === selectedHandedness ||
          selectedHandedness === "BOTH"
      );
      state.columnStates[index].filteredMatchData = filteredMatchData;

      // filter selected match data
      const filteredMatchDataIds = state.columnStates[
        index
      ].filteredMatchData.map((f) => f.shotEvolutionReportId);
      const selectedMatchData = state.columnStates[index].selectedMatchData;
      state.columnStates[index].selectedMatchData =
        selectedMatchData?.filter((m) =>
          filteredMatchDataIds.includes(m.shotEvolutionReportId)
        ) ?? [];
    },

    addColumn(state) {
      state.columnStates.push({
        columnName: "",
        selectedPlayer: null,
        selectedOptions: {},
        yearOptions: [],
        surfaceOptions: [],
        tournamentOptions: [],
        allMatchData: [],
        filteredMatchData: [],
        selectedMatchData: [],
        temporarySelection: [],
      });
    },

    setInitialState(state) {
      Object.assign(state, initialShotEvolutionReportState);
    },

    removeColumn(state, action) {
      const index = action.payload;
      if (state.columnStates.length > 2) {
        state.columnStates.splice(index, 1);
      }
    },

    setSelectedMatchData(state, action) {
      const { columnIndex, selectedData } = action.payload;
      const columnState = state.columnStates[columnIndex];
      columnState.selectedMatchData = selectedData;
    },
  },
});

export const {
  setColumnState,
  setColumnName,
  setReportName,
  setSelectedMatchData,
  updateColumnOptions,
  addColumn,
  removeColumn,
  setTemporarySelection,
  setInitialState,
} = shotEvolutionReportSlice.actions;

export const selectColumnStates = (state) =>
  state.shotEvolutionReport?.columnStates;
export const selectReportName = (state) =>
  state.shotEvolutionReport?.reportName;

export default shotEvolutionReportSlice.reducer;
