import { createSlice } from '@reduxjs/toolkit';
import authRoles from './authRoles';

const initialState = {
    user: null,
    roles: ['isBasic'],
    isAdmin: false,
    isCoder: false,
    isCV: false,
    isVideoClient: false,
    features: null
};
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
            if (action.payload === null) {
                state.roles = ['isBasic'];
            }
            else {
                newrelic.setUserId(action.payload.preferred_username);
                for (const [key, value] of Object.entries(authRoles)) {
                    for (const role of state.user.roles) {
                        if (value.includes(role)) {
                            state.roles.push(key);
                            state[key] = true;
                        }
                    }
                }
            }
        },
        setFeatures(state, action) {
            state.features = action.payload;
        }
    },
});
export const { setUser, setFeatures } = authSlice.actions;
export const selectUser = (state) => state.auth.user;
export const selectRoles = (state) => state.auth.roles;
export const selectIsAdmin = (state) => state.auth.isAdmin;
export const selectIsCoder = (state) => state.auth.isCoder;
export const selectIsCV = (state) => state.auth.isCV;
export const selectIsVideoClient = (state) => state.auth.isVideoClient;
export const selectIsAllowedPostMatchReport = (state) => state.auth.features ? state.auth.features.some(f => f.name == 'Post Match Report') : null;
export const selectIsAllowedShotEvolutionReport = (state) => state.auth.features ? state.auth.features.some(f => f.name == 'Shot Evolution Report') : null;
export const selectIsAllowedShotsPlayer = (state) => state.auth.features ? state.auth.features.some(f => f.name == 'Shots Player') : null;
export const selectIsAllowedReportGeneration = (state) => state.auth.features ? state.auth.features.some(f => f.name == 'Report Generation') : null;
export const selectIsAllowedBGReportGeneration = (state) => state.auth.features ? state.auth.features.some(f => f.name == 'BG Reports') : null;
export default authSlice.reducer;