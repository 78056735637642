const PathConstants = {
  INDEX: "/",
  LOGINCALLBACK: "/login/callback",
  RANKS: "ranks",
  SHOTSVIEW: "shotsview",
  CUSTOMCLIPS: "customclips",
  REPORTGENERATION: "reportgeneration",
  REPORTPREVIEW: "reportpreview",
  POSTMATCHLIST: "postmatchlist",
  SHOTEVOLUTIONREPORT: "shotevolutionreport",
};

export default PathConstants;
