const Modal = ({ children, onApplyFilter, columnIndex }) => {
  const handleApplyFilter = () => {
    onApplyFilter(columnIndex);
  };

  return (
    <div
      className="modal fade"
      id={`matchesListModal-${columnIndex}`}
      role="dialog"
      aria-labelledby={`matchesListModalLabel-${columnIndex}`}
      aria-hidden="true"
    >
      <div
        style={{ width: "fit-content", minWidth: "45%" }}
        className="modal-dialog"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3
              className="modal-title"
              id={`matchesListModalLabel-${columnIndex}`}
            >
              Filter Matches
            </h3>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="font-weight-light" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary btn-md"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              className="btn btn-warning btn-md"
              type="button"
              data-dismiss="modal"
              onClick={handleApplyFilter}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
