import React, { useEffect, useState } from "react";
import { useOktaAuth } from '@okta/okta-react';
import { NavLink } from "react-router-dom";
import PathConstants from "./routes/pathConstants";
import RequiredAuth from "./routes/SecureRoute";
import { useDispatch } from 'react-redux';
import { setUser, setFeatures, selectIsAllowedPostMatchReport, selectIsAllowedShotEvolutionReport } from "./features/auth/authSlice";
import { useGetUserFeaturesQuery } from "./features/api/userSlice";

import { useSelector } from "react-redux";
import { selectIsVideoClient, selectIsAllowedReportGeneration, selectIsAllowedShotsPlayer } from "./features/auth/authSlice";

export default function Layout() {
    const dispatch = useDispatch();
    const isVideoClient = useSelector(selectIsVideoClient);
    const isAllowedShotsPlayer = useSelector(selectIsAllowedShotsPlayer);
    const isAllowedReportGeneration = useSelector(selectIsAllowedReportGeneration);
    const isAllowedPostMatchReport = useSelector(selectIsAllowedPostMatchReport);
    const isAllowedShotEvolutionReport = useSelector(selectIsAllowedShotEvolutionReport);
    const { authState, oktaAuth } = useOktaAuth();
    const handleLogin = () => oktaAuth.signInWithRedirect();
    const handleLogout = () => {
        oktaAuth.signOut();
        dispatch(setUser(null));
    }

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (authState && authState.isAuthenticated) {
            setTimeout(() => {
                oktaAuth.getUser().then((info) => {
                    dispatch(setUser(info));
                    setIsAuthenticated(true); // Set authenticated state to true
                });
            }, 400);
        }
    }, [authState, oktaAuth, dispatch]);

    // Call the query only if authenticated
    const { data: features } = useGetUserFeaturesQuery({ skip: !isAuthenticated });

    useEffect(() => {
        if (features) {
            dispatch(setFeatures(features));
        }
    }, [features, dispatch]);

    return (
        <div>
            <main className="main" id="top">
                <div className="container-fluid" data-layout="container">
                    <nav className="navbar navbar-dark navbar-glass navbar-top sticky-kit navbar-expand-lg">
                        <button className="btn navbar-toggler-humburger-icon navbar-toggler mr-1 mr-sm-3" type="button" data-toggle="collapse" data-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle Navigation">
                            <span className="navbar-toggle-icon">
                                <span className="toggle-line"></span>
                            </span>
                        </button>
                        <a className="navbar-brand" href="/shotsview">
                            <div className="d-flex align-items-center py-3">
                                <img
                                    className="mr-2"
                                    src="assets/img/gsa-full-logo-gold.png"
                                    alt=""
                                    width="105"
                                />
                            </div>
                        </a>
                        <div className="collapse navbar-collapse" id="navbarStandard">
                            <ul className="navbar-nav">
                                {isVideoClient && isAllowedShotsPlayer &&
                                    <>
                                        <li className="nav-item">
                                            <NavLink activeclassname="active" to={PathConstants.SHOTSVIEW} className="nav-link">Shots View</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink activeclassname="active" to={PathConstants.CUSTOMCLIPS} className="nav-link">User Video Clips</NavLink>
                                        </li>
                                    </>
                                }
                                {isVideoClient && isAllowedReportGeneration &&
                                    <li className="nav-item">
                                        <NavLink activeclassname="active" to={PathConstants.REPORTGENERATION} className="nav-link">Report Generation</NavLink>
                                    </li>
                                }
                                {isVideoClient && isAllowedPostMatchReport &&
                                    <li className="nav-item">
                                        <NavLink activeclassname="active" to={PathConstants.POSTMATCHLIST} className="nav-link">Post-Match</NavLink>
                                    </li>
                                }
                                {isVideoClient && isAllowedShotEvolutionReport &&
                                    <li className="nav-item">
                                        <NavLink activeclassname="active" to={PathConstants.SHOTEVOLUTIONREPORT} className="nav-link">Shot Evolution Report</NavLink>
                                    </li>
                                }
                            </ul>
                        </div>
                        <ul className="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center">
                            {
                                !authState || !authState.isAuthenticated ?
                                    (
                                        <li className='nav-item'>
                                            <div className="bg-white rounded-soft py-2">
                                                <a style={{ cursor: "pointer" }} onClick={handleLogin}>Login</a>
                                            </div>
                                        </li>
                                    ) :
                                    (
                                        <li className='nav-item dropdown dropdown-on-hover'>
                                            <a className="nav-link pr-0" id="navbarDropdownUser" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div className="avatar avatar-xl">
                                                    <span className='far fa-user-circle fs-4'></span>
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="navbarDropdownUser">
                                                <div className="bg-white rounded-soft py-2">
                                                    <div className="dropdown-item">
                                                        {authState.idToken.claims.preferred_username}
                                                    </div>
                                                    <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={handleLogout}>Logout</a>
                                                </div>
                                            </div>
                                        </li>
                                    )
                            }
                        </ul>
                    </nav>
                    <div className="content">
                        <div className="pt-2"></div>
                        <RequiredAuth />
                    </div>
                </div>
            </main>
        </div>
    );
}