import React, { useState } from "react";
import Spinner from "../../UI/Spinner";
import {
  selectIsAllowedShotEvolutionReport,
  selectIsVideoClient,
  selectUser,
} from "../auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import Select from "react-select";
import DeleteButton from "../../UI/buttons/DeleteButton";
import classes from "./ShotEvolutionReportPage.module.css";
import {
  useGetDistinctPlayersQuery,
  useLazyGetPlayerByIdQuery,
} from "../api/shotEvolutionSlice";
import {
  setColumnState,
  updateColumnOptions,
  addColumn,
  removeColumn,
  selectColumnStates,
  selectReportName,
  setColumnName,
  setReportName,
  setSelectedMatchData,
  setInitialState,
} from "./store/shotEvolutionReportSlice";
import Modal from "./Modal";

const ShotEvolutionReportPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isVideoClient = useSelector(selectIsVideoClient);
  const isAllowedShotEvolutionReport = useSelector(
    selectIsAllowedShotEvolutionReport
  );
  const columns = useSelector(selectColumnStates);
  const reportName = useSelector(selectReportName);
  const [modalData, setModalData] = useState({
    index: -1,
    filteredMatchData: [],
    selectedRows: [],
    areAllMatchesSelected: false,
  });

  const { data: players, isLoading: isLoadingPlayers } =
    useGetDistinctPlayersQuery();

  const [getPlayerById] = useLazyGetPlayerByIdQuery();

  const addColumnHandler = () => {
    dispatch(addColumn());
  };

  const refreshData = () => {
    dispatch(setInitialState());
  };

  const removeColumnHandler = (index) => {
    dispatch(removeColumn(index));
  };

  const handednessOptions = [
    { value: "RIGHT", label: "RIGHT" },
    { value: "LEFT", label: "LEFT" },
    { value: "BOTH", label: "BOTH" },
  ];

  const handlePlayerSelectChange = async (index, selectedOption) => {
    const columnState = {
      selectedPlayer: selectedOption,
      selectedOptions: { handedness: handednessOptions[0] },
      yearOptions: [],
      surfaceOptions: [],
      tournamentOptions: [],
      temporarySelection: [],
    };
    dispatch(setColumnState({ index, columnState }));
    if (selectedOption) {
      const { data: playerData } = await getPlayerById(selectedOption.value);
      if (playerData) {
        dispatch(updateColumnOptions({ index, playerData }));
      }
    }
  };

  const handleFilterSelectChange = (index, name, selectedOption) => {
    const columnState = { ...columns[index] };
    columnState.selectedOptions = { ...columnState.selectedOptions };
    columnState.selectedOptions[name] = selectedOption;
    dispatch(setColumnState({ index, columnState }));
  };

  const onApplyFilter = (columnIndex) => {
    dispatch(
      setSelectedMatchData({
        columnIndex,
        selectedData: modalData.selectedRows,
      })
    );
  };

  const handleReportNameChange = (event) => {
    dispatch(setReportName(event.target.value));
  };

  const handleColumnNameChange = (index, event) => {
    dispatch(setColumnName({ index, columnName: event.target.value }));
  };

  const prepareModalData = (index) => {
    return () => {
      const selectedRows = columns[index].selectedMatchData;
      const filteredMatchData = columns[index].filteredMatchData;
      setModalData({
        index,
        filteredMatchData: filteredMatchData,
        selectedRows: selectedRows,
        areAllMatchesSelected:
          selectedRows?.length === filteredMatchData?.length &&
          !!filteredMatchData?.length,
      });
    };
  };

  const onSelectedRowsChange = (match) => {
    setModalData((prevState) => {
      const selectedRows = [...prevState.selectedRows];
      const index = selectedRows.findIndex(
        (row) => row === match.shotEvolutionReportId
      );
      if (index > -1) {
        selectedRows.splice(index, 1);
      } else {
        selectedRows.push(match.shotEvolutionReportId);
      }
      const areAllMatchesSelected =
        selectedRows.length === prevState.filteredMatchData.length &&
        !!prevState.filteredMatchData.length;
      return {
        ...prevState,
        selectedRows,
        areAllMatchesSelected,
      };
    });
  };

  const matchesMultipleSelection = (value) => {
    setModalData((prevState) => {
      const selectedRows = value
        ? [...prevState.filteredMatchData].map((m) => m.shotEvolutionReportId)
        : [];
      return {
        ...prevState,
        selectedRows,
        areAllMatchesSelected: value,
      };
    });
  };

  const handleSendData = () => {
    const dataToSend = {
      reportName,
      playerData: columns.map((column) => ({
        columnName: column.columnName,
        playerId: column.selectedPlayer.value,
        selectedMatchData:
          column.selectedMatchData.length > 0
            ? column.allMatchData
                .filter((match) =>
                  column.selectedMatchData.includes(match.shotEvolutionReportId)
                )
                .map((match) => match.matchId)
            : column.filteredMatchData.map((match) => match.matchId),
      })),
    };

    sendData(dataToSend);
  };

  if (!user) return <Spinner />;
  else if (!isVideoClient || !isAllowedShotEvolutionReport)
    return <NotAuthorizedPage />;

  return (
    <div>
      <header>
        <h2>PLAYER EVOLUTION/COMPARISON CREATOR</h2>
      </header>{" "}
      <div className={`${classes["content-container"]}`}>
        <input
          type="text"
          placeholder="Enter report name"
          className={classes["report-name-input"]}
          value={reportName}
          onChange={handleReportNameChange}
        />
        <ColumnsContainer
          columns={columns}
          removeColumnHandler={removeColumnHandler}
          customStyles={customStyles}
          players={players}
          isLoadingPlayers={isLoadingPlayers}
          handednessOptions={handednessOptions}
          prepareModalData={prepareModalData}
          handleColumnNameChange={handleColumnNameChange}
          handleFilterSelectChange={handleFilterSelectChange}
          handlePlayerSelectChange={handlePlayerSelectChange}
        />
        {columns.length < 10 && (
          <button
            onClick={addColumnHandler}
            className={classes["add-column-button"]}
          >
            Add new column
          </button>
        )}
        <button onClick={refreshData} className={classes["add-column-button"]}>
          Refresh data
        </button>
        <button
          onClick={handleSendData}
          disabled={columns.some((column) => !column.selectedPlayer)}
          className={classes["add-column-button"]}
        >
          Send Data
        </button>
      </div>
      (
      <Modal onApplyFilter={onApplyFilter} columnIndex={modalData.index}>
        {/* <DataTable
          columns={columnList}
          data={modalData.filteredMatchData}
          responsive
          highlightOnHover
          theme="dark"
          noDataComponent="No matches available"
          selectableRows
          onSelectedRowsChange={onSelectedRowsChange}
          selectableRowSelected={isRowSelected}
        /> */}
        <div
          className={`d-flex justify-content-center ${classes["table-container"]}`}
        >
          <table
            className={`table table-sm table-dark table-responsive scrollbar perfect-scrollbar ${classes["matches-table"]}`}
          >
            <caption>Matches List</caption>
            <thead>
              <tr>
                <th scope="col">
                  <input
                    type="checkbox"
                    checked={modalData.areAllMatchesSelected}
                    onChange={() =>
                      matchesMultipleSelection(!modalData.areAllMatchesSelected)
                    }
                  />
                </th>
                <th scope="col" className="text-nowrap">
                  Opponent
                </th>
                <th scope="col" className="text-nowrap">
                  Tournament
                </th>
                <th scope="col" className="text-nowrap">
                  Year
                </th>
                <th scope="col" className="text-nowrap">
                  Round
                </th>
              </tr>
            </thead>
            <tbody>
              {modalData.filteredMatchData.map((match) => (
                <tr key={match.shotEvolutionReportId}>
                  <th scope="row">
                    <input
                      type="checkbox"
                      checked={modalData.selectedRows?.includes(
                        match.shotEvolutionReportId
                      )}
                      onChange={() => onSelectedRowsChange(match)}
                    />
                  </th>
                  <td className="text-nowrap">{match.opponent}</td>
                  <td className="text-nowrap">{match.tournament}</td>
                  <td className="text-nowrap">{match.year}</td>
                  <td className="text-nowrap">{match.round}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
      )
    </div>
  );
};

const ColumnsContainer = ({
  columns,
  removeColumnHandler,
  customStyles,
  players,
  isLoadingPlayers,
  handednessOptions,
  prepareModalData,
  handleColumnNameChange,
  handleFilterSelectChange,
  handlePlayerSelectChange,
}) => (
  <div className={classes["columns-container"]}>
    {columns.map((column, index) => (
      <div key={index} className={classes["column"]}>
        {columns.length > 2 && (
          <DeleteButton
            className={classes["remove-column-button"]}
            classes="mr-2"
            onClick={() => removeColumnHandler(index)}
          />
        )}
        <input
          type="text"
          placeholder="Enter column name"
          className={classes["column-name-input"]}
          value={column.columnName || ""}
          onChange={(event) => handleColumnNameChange(index, event)}
        />
        <div className={classes["select-group"]}>
          <label>Player</label>
          <Select
            className={classes["select-field"]}
            placeholder="Select..."
            styles={customStyles}
            options={players?.map((player) => ({
              value: player.item1,
              label: player.item2,
            }))}
            onChange={(selectedOption) =>
              handlePlayerSelectChange(index, selectedOption)
            }
            required
            isLoading={isLoadingPlayers}
            value={columns[index].selectedPlayer || ""}
            isSearchable
          />
          <label>Year(s)</label>
          <Select
            className={classes["select-field"]}
            placeholder="Select..."
            isMulti
            styles={customStyles}
            isDisabled={!columns[index].selectedPlayer}
            options={columns[index].yearOptions}
            value={columns[index].selectedOptions.years || []}
            isLoading={isLoadingPlayers}
            onChange={(selectedOption) =>
              handleFilterSelectChange(index, "years", selectedOption)
            }
          />
          <label>Surfaces</label>
          <Select
            className={classes["select-field"]}
            placeholder="Select..."
            options={columns[index].surfaceOptions}
            isMulti
            styles={customStyles}
            isDisabled={!columns[index].selectedPlayer}
            value={columns[index].selectedOptions.surfaces || []}
            isLoading={isLoadingPlayers}
            onChange={(selectedOption) =>
              handleFilterSelectChange(index, "surfaces", selectedOption)
            }
          />
          <label>Tournaments</label>
          <Select
            className={classes["select-field"]}
            placeholder="Select..."
            options={columns[index].tournamentOptions}
            isMulti
            styles={customStyles}
            isDisabled={!columns[index].selectedPlayer}
            value={columns[index].selectedOptions.tournaments || []}
            isLoading={isLoadingPlayers}
            onChange={(selectedOption) =>
              handleFilterSelectChange(index, "tournaments", selectedOption)
            }
          />
          <label>Opponent handedness</label>
          <Select
            className={classes["select-field"]}
            options={handednessOptions}
            styles={customStyles}
            isDisabled={!columns[index].selectedPlayer}
            value={columns[index].selectedOptions.handedness}
            onChange={(selectedOption) =>
              handleFilterSelectChange(index, "handedness", selectedOption)
            }
          />
          <button
            className={classes["view-matches-button"]}
            data-toggle="modal"
            data-target={`#matchesListModal-${index}`}
            onClick={prepareModalData(index)}
            disabled={!columns[index].selectedPlayer}
          >
            View Matches
          </button>
        </div>
      </div>
    ))}
  </div>
);

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: "black",
  }),
  control: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? "default" : "pointer",
    opacity: state.isDisabled ? "0.5" : "1",
  }),
};

const columnList = [
  {
    name: "Opponent",
    selector: (clip) => clip.opponent,
    width: "30%",
  },
  {
    name: "Tournament",
    selector: (clip) => clip.tournament,
    width: "25%",
  },
  {
    name: "Year",
    selector: (clip) => clip.year,
    width: "20%",
  },
  {
    name: "Round",
    selector: (clip) => clip.round,
    width: "20%",
  },
];

const sendData = async (dataToSend) => {
  try {
    // TODO: Replace with actual endpoint once it is done
    const response = await fetch("https://dummy-endpoint.com/api/get-url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });

    if (response.ok) {
      const result = await response.json();
      const url = result.url;
      window.open(url, "_blank");
    } else {
      console.error("Failed to fetch URL");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export default ShotEvolutionReportPage;
