import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const shotEvolutionSlice = createApi({
  reducerPath: "shotEvolutionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDistinctPlayers: builder.query({
      query: () => "ShotEvolutionReport/distinct-players",
    }),
    getPlayerById: builder.query({
      query: (playerId) => `ShotEvolutionReport/player/${playerId}`,
    }),
  }),
});

export const { useGetDistinctPlayersQuery, useLazyGetPlayerByIdQuery } =
  shotEvolutionSlice;
